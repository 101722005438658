import React, { useEffect, useState, createRef, lazy, useContext } from "react";
import { UserContext } from "./UserContext";

import jwt_decode from "jwt-decode";

import axios from "axios";

import { webaddress } from "./webaddress";

export async function getProtectedAsset(url, user, setUser, data = null) {
  //If we do not have a token
  if (user["userID"] == -1) {
    return "Error: No token";
  } else {
    try {

      //Get user data
      const token = {
        accessToken: user["accessToken"],
        email: user["email"],
        userID: user["userID"],
      };


      //Check if accessToken is about to expire (60s mairgain)
      if (
        Date.now() >=
        jwt_decode(token["accessToken"])["exp"] * 1000 - 60000
      ) {
        //Get new token
        const res = await axios
          .post(webaddress + "/refreshtoken", {
            token: user["refreshToken"],
          })
          .then((res) => {
            setUser({
              userID: user["userID"],
              email: user["email"],
              accessToken: res.data["accessToken"],
              refreshToken: user["refreshToken"],
              accountType: user["accountType"],
            });
          })
          .catch((err) => {
            console.error(err);
          });
      }

      //Add data to token if there are any data
      if (data != null) {
        token.data = data;
      }

      //Our token is fresh
      return await axios.post(url, token);




      /*const res = await axios
        .post(url, token)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.error(err);
        });*/
    } catch (error) {
      console.log(error);
      throw err;
    }
  }
}
