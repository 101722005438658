import React, { Component, Suspense, useState, useMemo } from "react";
import { HashRouter, Route, Switch, withRouter } from "react-router-dom";
import "./scss/style.scss";

import { UserContext } from "./UserContext";
import { getProtectedAsset } from "./getProtectedAsset";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Validate_email = React.lazy(() =>
  import("./views/pages/validate_email/Validate_email")
);
const Register_club = React.lazy(() =>
  import("./views/pages/register_club/Register_club")
);

const Send_Reset_Code = React.lazy(() =>
  import("./views/pages/forgot_password/Send_reset_code")
);

const Verify_reset_code = React.lazy(() =>
  import("./views/pages/forgot_password/Verify_reset_code")
);

const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

function App() {
  const [user, setUser] = useState({
    userID: -1,
  });
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={value}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              path="/reset_password"
              name="Reset Password"
              render={(props) => <Send_Reset_Code {...props} />}
            />
            <Route
              path="/verify_reset_code"
              name="Verify Reset Code"
              render={(props) => <Verify_reset_code {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/validate_email"
              name="Validate_email"
              render={(props) => <Validate_email {...props} />}
            />
            <Route
              exact
              path="/register_club"
              name="Register Club"
              render={(props) => <Register_club {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </UserContext.Provider>
  );
}

export default App;

/*function App() {
  const [user, setUser] = useState({
    userID: -1,
  });
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={value}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              element={<Login />}
              
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/register_club"
              name="Register Club Page"
              render={(props) => <Register_club {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </UserContext.Provider>
  );
}

export default App;*/

/*return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />

          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route
            exact
            path="/register_club"
            name="Register Club Page"
            render={(props) => <Register_club {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <DefaultLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );*/

/*class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/register_club"
              name="Register Club Page"
              render={(props) => <Register_club {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;*/
